<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>
        <ion-title>{{ $route.name }}</ion-title>
        <ion-button @click="goToHome()" color="primary" fill="clear" slot="end">
          <ion-icon icon="home" />
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-loading
        :is-open="loading"
        cssClass="loading"
        message="Caricamento..."
        :duration="timeout"
      />
      <ion-list v-if="docs">
        <div v-for="n in docs" :key="n" lines="none">
          <jn-doc :doc="n" :showAbstract="false" />
        </div>
      </ion-list>

      <ion-infinite-scroll
        @ionInfinite="loadDocuments($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Caricamento..."
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import JnDoc from '../../components/DocCard.vue';
import { document, search } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import { EsService } from '@/services/es.service';
import { Categories } from '@/models/enums.model';
import * as eventBus from '../../services/event.bus.js';
import { useRouter, useRoute } from 'vue-router';

addIcons({
  document: document,
  search: search,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  alertController,
} from '@ionic/vue';

export default defineComponent({
  name: 'JnCodici',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    // IonButtons,
    //IonMenuToggle,
    // IonTitle,
    // IonMenuButton,
    //  IonGrid,
    //IonRow,
    // IonCol,
    //  IonText,
    JnDoc,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    // IonItem,
    IonList,
    IonLoading,
    IonIcon,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonTitle,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const isDisabled = ref(false);
    const docs = ref([] as Document[]);
    const docsSize = ref();
    const scrollComponent = ref(null);

    const loading = ref(true);
    const timeout = ref(5000);

    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES,
      from: 0,
      size: 10,
      idCategory: [Categories.CODICI],
      withText: true,
      searchInMassime: false
    });

    function goToHome() {
      // eventBus.default.$emit('reset-results');
      router.push('/main');
    }

    const showAlert = async (message: string) => {
      const alert = await alertController.create({
        header: 'Errore',
        message: message,
        buttons: [
          {
            text: 'Riprova',
            role: 'retry',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      try {
        docs.value = await EsService.getDocs(searchData);
        docsSize.value = await EsService.getDocs(searchData);
      } catch (err) {
        await showAlert(err.message);
      }
    };

    const pushDocuments = async () => {
      searchData.from = docs.value.length;
      const allDocs = await EsService.getDocs(searchData);
      for (let i = 0; i < allDocs.length; i++) {
        docs.value.push(allDocs[i]);
      }
    };

    onMounted(async () => {
      loading.value = true;
      try {
        docs.value = await EsService.getDocs(searchData);
        docsSize.value = await EsService.getDocs(searchData);
      } catch (err) {
        loading.value = false;
        showAlert(err.message);
      }
      loading.value = false;
      eventBus.default.$on('reset-results', function(data: string) {
        docs.value = [];
        pushDocuments();
      });
    });

    const loadDocuments = async (ev: CustomEvent) => {
      setTimeout(() => {
        pushDocuments();
        const target = ev.target as HTMLIonInfiniteScrollElement;
        target.complete();

        // App logic to determine if all data is loaded
        // and disable the infinite scroll
        if (docs.value.length == docsSize.value) {
          target.disabled = true;
        }
      }, 500);
    };

    pushDocuments();

    return {
      isDisabled,
      docs,
      scrollComponent,
      loadDocuments,
      timeout,
      loading,
      goToHome,
    };
  },
});
</script>
